/**
 * Action types for checkout
 */
export default {
  CLEAR_CHECKOUT_ERROR: 'CLEAR_CHECKOUT_ERROR',
  CLEAR_CHECKOUT_MESSAGE: 'CLEAR_CHECKOUT_MESSAGE',
  RECIEVE_POST_ORDER: 'RECIEVE_POST_ORDER',
  RESET_CHECKOUT: 'RESET_CHECKOUT',
  SEND_POST_ORDER: 'SEND_POST_ORDER',
  SET_ASAP_DELIVERY: 'SET_ASAP_DELIVERY',
  SET_AUTO_SELECTED_PAYMENT_METHOD: 'SET_AUTO_SELECTED_PAYMENT_METHOD',
  SET_CHECKOUT_ADDRESS: 'SET_CHECKOUT_ADDRESS',
  SET_CHECKOUT_MESSAGE: 'SET_CHECKOUT_MESSAGE',
  SET_CHECKOUT_NOTES: 'SET_CHECKOUT_NOTES',
  SET_DEPOT_MISMATCH: 'SET_DEPOT_MISMATCH',
  SET_DELIVERY_WINDOW: 'SET_DELIVERY_WINDOW',
  SET_CHECKOUT_ERROR: 'SET_CHECKOUT_ERROR',
  SET_DELIVERY_NOTES: 'SET_DELIVERY_NOTES',
  TOGGLE_CARD_DECLINE_DRAWER: 'TOGGLE_CARD_DECLINE_DRAWER',
  TOGGLE_ACH_FIX_DRAWER: 'TOGGLE_ACH_FIX_DRAWER',
  TRACK_CREDIT_OPTION: 'TRACK_CREDIT_OPTION',
  VIEW_CHECKOUT: 'VIEW_CHECKOUT'
}
