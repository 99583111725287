import { ISplitState, splitReducer } from '@splitsoftware/splitio-redux'

export const defaultState = <ISplitState>{ isReady: false, treatments: {} }

// creating a wrapper bc splitReducer expects an ISplitAction
// and breaks if there's not a payload
const splitio = function (state = defaultState, action: { type: string; payload: any }) {
  if (!action.payload) return state
  return splitReducer(state, action)
}

export default splitio
